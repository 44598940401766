import React, { useState } from "react";
// import Icon1  from '../../images/trade.svg'
// import Icon2  from '../../images/money.svg'
// import Icon3  from '../../images/done.svg'
// import Icon4 from '../../images/bridge.svg'
import {
  NavBtn,
  NavBtnLink,
} from "../Navbar/NavbarElements";

import { HowtobuyContainer, HowtobuyH1, HowtobuyH2, HowtobuyWrapper, HowtobuyCard, HowtobuyIcon, Howtobuyp } from './HowtobuyElements'

export const Howtobuy = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HowtobuyContainer id="howtobuy">
      <HowtobuyH1>Explore Your NFTs</HowtobuyH1>
     
      <HowtobuyWrapper>
        <HowtobuyCard>
          <HowtobuyIcon src="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/pink.png" />
          <HowtobuyH2>Color type : pink</HowtobuyH2>
          {/* <Howtobuyp>
            check in opensea
            <a
              href="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/pink.png"
              target="_blank}"
            >
           {" "}
            </a>
          </Howtobuyp> */}
        </HowtobuyCard>

        <HowtobuyCard>
          <HowtobuyIcon src="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/green.jpg" />
          <HowtobuyH2>Color type : Green</HowtobuyH2>
          {/* <Howtobuyp>
            Bridge your native SNT to Fantom SNT using the
            <a href="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/pink.jpg" target="_blank}">
              {" "}
              link{" "}
            </a>
          </Howtobuyp> */}
        </HowtobuyCard>

        <HowtobuyCard>
          <HowtobuyIcon src="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/blue.png" />
          <HowtobuyH2>Color type : Blue</HowtobuyH2>
          {/* <Howtobuyp>
            Add Nova Network to Metamask, from
            <a href="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/blue.png" target="_blank}">
              {" "}
              ChainList{" "}
            </a>
          </Howtobuyp> */}
        </HowtobuyCard>
        <HowtobuyCard>
          <HowtobuyIcon src="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/yellow.jpg" />
          <HowtobuyH2>Color type : Yellow</HowtobuyH2>
          {/* <Howtobuyp>
            Add Nova Network to Metamask, from
            <a href="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/blue.png" target="_blank}">
              {" "}
              ChainList{" "}
            </a>
          </Howtobuyp> */}
        </HowtobuyCard>

        <HowtobuyCard>
          <HowtobuyIcon src="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/pink.jpg" />
          <HowtobuyH2>Color type : Red</HowtobuyH2>
          {/* <Howtobuyp>
            You can buy
            <a
              href="https://bafybeidzxhiyx2asckk7mxh2xlo45gkx2wothspz22q5lgyi5u7f3ebdou.ipfs.nftstorage.link/green.jpg"
              target="_blank}"
            >
              {" "}
              Enigma token
            </a>{" "}
            from here
          </Howtobuyp> */}
        </HowtobuyCard>
        <NavBtn style={{display: "flex"}}>
              <NavBtnLink target="_blank" to="https://opensea.io/collection/enigma404-1">
                {" "}
                Open Sea
              </NavBtnLink>
              {" "}
              <NavBtnLink target="_blank" to="https://avax.hyperspace.xyz/collection/avax/1778bacc-52e7-4c65-bd3e-268a36910c48">
                {" "}
                HyperSpace AVAX
              </NavBtnLink>
            </NavBtn>
    
      </HowtobuyWrapper>
      
    </HowtobuyContainer>
  );
}
