import React, { useState } from "react";
import Video from "../../videos/video.mp4";
import { Button } from "../ButtonElements";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>
        Enigma404 is the First ERC20 / ERC721 hybrid token on AVAX.
        </HeroH1>
        <HeroP>
          This is modified FORK of ERC404 token standard as created by 0xacme /
          Pandora. This is an experimental, mixed ERC20 / ERC721 implementation
          with native liquidity and fractionalization. This mixed ERC20 / ERC721
          tokens are instead burned and minted repeatedly as per underlying
          token transfers.
        </HeroP>
        {/* <HeroBtnWrapper>
          <a
            href=""
            target="_blank" rel="noreferrer"
            
          >
            <Button
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
            >
              Chart {hover ? <ArrowForward /> : <ArrowRight />}
            </Button>
          </a>
        </HeroBtnWrapper> */}
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
