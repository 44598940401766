import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaDiscord, FaTelegram, FaTwitter } from "react-icons/fa";
import {
  FooterWrap,
  FooterContainer,
  SocialMedia,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMediaWrap,
  WebsiteRights,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              Enigma 404
            </SocialLogo>
            <WebsiteRights>
              Copyright © {new Date().getFullYear()} enigma.All rights reserved
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://t.me/enigma404avax"
                target="_blank"
                aria-label="Telegram"
              >
                <FaTelegram />
              </SocialIconLink>

              <SocialIconLink style={{alignContent:'center'}} href="https://twitter.com/Enigma404x" target="_blank" aria-label="Twitter">
                <FaTwitter />
              </SocialIconLink>

              <SocialIconLink href="" target="_blank" aria-label="Discord">
                {/* <FaDiscord /> */}
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
