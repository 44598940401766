import React, {useState} from 'react';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection/Index';
import { Howtobuy } from '../components/Howtobuy';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo, homeObjThree } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

const Home = () => {
const[isOpen, setIsOpen] = useState(false);

const toggle = () =>{
  setIsOpen(!isOpen);
};


  return (
    <>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle ={toggle}/> 
<HeroSection/>
<InfoSection {...homeObjOne}/>
<InfoSection {...homeObjTwo}/>
<InfoSection {...homeObjThree}/>
<Howtobuy />
<Footer />
    </>
  );
};

export default Home;