export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "About",
  headline: "Why Enigma?",
  description:
    "This hybrid semi-fungible NFT with regular ERC20 token can be traded in a regular dex, utilizing the new ERC404 token standard. This mixed ERC20 / ERC721 implementation allows native liquidity and fractionalization of the NFT.  Enigma404 was hard forked from Pandora ERC404 and has all tokens in circulation with a hard cap of 10,000 tokens.",
  buttonLabel: "Something",
  imgStart: false,
  img: require("../../images/svg-1.svg").default,
  alt: "enigma",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "tokenomics",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Tokenomics",
  headline: "Circulation: 100% Hard cap: 10,000 Tax : 0% ",
  description:
    "LP locked, No hidden DEV wallet, No team Wallet. No mints of new tokens are possible. New NFT mint and burn in every token transaction. No mint fee needed for enigma fractional NFT.",
  buttonLabel: "Something",
  imgStart: true,
  img: require("../../images/svg-5.svg").default,
  alt: "enigma",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "roadmap",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Roadmap",
  headline: "Goal of the project",
  description:
    "Enigma is in partnership with ThriftySOFi. Thrifty does not have a token. There is an ongoing discussion for a partnership to make Enigma as default platform token for ThriftySofi.",
  buttonLabel: "Something",
  imgStart: true,
  img: require("../../images/svg-2.svg").default,
  alt: "enigma",
  dark: false,
  primary: false,
  darkText: true,
};
